<template>
  <div
    class="fixed z-40 top-0 bottom-0 left-0 right-0 h-full w-full bg-black bg-opacity-50 flex justify-end"
  >
    <div class="w-3/4 h-full cursor-pointer" @click="$emit('close')"></div>
    <div class="w-1/4 h-full bg-white rounded-l-2xl p-10 overflow-auto">
      <div class="flex items-center justify-between">
        <h1 class="text-black font-bold text-xl">
          {{ action === "add" ? "Add" : "Edit" }} Enquiry
        </h1>
        <div
          class="bg-red-500 bg-opacity-30 p-5 rounded-full cursor-pointer"
          @click="$emit('close')"
        >
          <CloseIcon />
        </div>
      </div>
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="createNewFridge" class="text-left mt-10">
          <ValidationProvider
            name="Family Name"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label for="name">Family Name</label>
              <input
                id="name"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Family Name"
                v-model="enquiry.family_name"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Name of Deceased"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label for="name">Name of Deceased</label>
              <input
                id="name"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Name of Deceased"
                v-model="enquiry.name_of_deceased"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Contact Person Email"
            rules="required|email"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label for="name">Contact Person Email</label>
              <input
                id="name"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Contact Person Email"
                v-model="enquiry.contact_person_email"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Contact Person Phone Number"
            rules="required|numeric|length:11"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label for="name">Contact Person Phone Number</label>
              <input
                id="name"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Contact Person Phone Number"
                v-model="enquiry.contact_person_phone_number"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Cause of Death"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label for="name">Cause of Death</label>
              <input
                id="name"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Cause of Death"
                v-model="enquiry.cause_of_death"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Location"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label for="name">Location</label>
              <input
                id="name"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Location"
                v-model="enquiry.location"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Purpose of Enquiry and Comment"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label for="name">Purpose of Enquiry and Comment</label>
              <textarea
                id="name"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full h-32"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Purpose of Enquiry and Comment"
                v-model="enquiry.purpose_of_enquiry_and_comment"
              ></textarea>
            </div>
          </ValidationProvider>
          <button
            class="w-full mt-8 text-white py-3 rounded-md bg-opacity-80 transition-all hover:bg-opacity-100 disabled:cursor-not-allowed"
            :class="invalid || submitting ? 'bg-gray-300' : 'bg-primary'"
            :disabled="invalid || submitting"
          >
            {{ action === "add" ? "Add" : "Edit" }} Enquiry
          </button>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { createEnquiry, editEnquiry } from "@/services/enquiry";

export default {
  name: "AddNewFridge",
  components: {
    CloseIcon: () => import("@/assets/icons/CloseIcon.vue"),
  },
  props: {
    newEnquiry: {
      required: false,
      type: Object,
    },
  },
  mounted() {
    if (this.newEnquiry.id) {
      this.enquiry = this.newEnquiry;
      this.action = "edit";
    }
  },
  data() {
    return {
      enquiry: {
        family_name: "",
        name_of_deceased: "",
        contact_person_phone_number: "",
        contact_person_email: "",
        cause_of_death: "",
        location: "",
        purpose_of_enquiry_and_comment: "",
      },
      action: "add",
      submitting: false,
    };
  },
  methods: {
    async createNewFridge() {
      this.submitting = true;
      if (this.action === "add") {
        try {
          await createEnquiry(this.enquiry);
          this.$toast.success("New enquiry created.");
          this.$emit("created");
        } catch (error) {
          this.$toast.error(error.response.data.message || error);
          console.log(error);
        }
      } else {
        try {
          const payload = {
            id: "" + this.enquiry.id,
            family_name: this.enquiry.family_name,
            name_of_deceased: this.enquiry.name_of_deceased,
            contact_person_phone_number:
              this.enquiry.contact_person_phone_number,
            contact_person_email: this.enquiry.contact_person_email,
            cause_of_death: this.enquiry.cause_of_death,
            location: this.enquiry.location,
            purpose_of_enquiry_and_comment:
              this.enquiry.purpose_of_enquiry_and_comment,
          };
          await editEnquiry(payload);
          this.$toast.success("Enquiry updated");
          this.$emit("created");
        } catch (error) {
          console.log(error);
          this.$toast.error(error.response.data.message || error);
        }
      }
      this.submitting = false;
    },
  },
};
</script>

<style></style>
